@font-face {
  font-family: Garnett;
  src: url(/fonts/Garnett/Garnett-Semibold.woff2) format('woff2'),
    url(/fonts/Garnett/Garnett-Semibold.woff) format('woff'),
    url(/fonts/Garnett/Garnett-Semibold.eot);
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Garnett;
  src: url(/fonts/Garnett/Garnett-Medium.woff2) format('woff2'),
    url(/fonts/Garnett/Garnett-Medium.woff) format('woff'), url(/fonts/Garnett/Garnett-Medium.eot);
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Garnett;
  src: url(/fonts/Garnett/Garnett-Regular.woff2) format('woff2'),
    url(/fonts/Garnett/Garnett-Regular.woff) format('woff'), url(/fonts/Garnett/Garnett-Regular.eot);
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Hunter;
  src: url(/fonts/Hunter/hunter-webfont.woff2) format('woff2'),
    url(/fonts/Hunter/hunter-webfont.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

figure,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  /* font-weight: bold;  */
}

p {
  margin-block-start: unset;
  /* margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset; */
  /* font-weight: bold;  */
}

p:last-child {
  margin-block-end: unset;
}
